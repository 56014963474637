import { api } from "./config/axiosConfig"

export const PlaidAPI = {
  get_link_token: async function () {

    const response = await api.request({
      url: `plaid/linkToken`,
      method: "GET"
    })
    
    // returning the product returned by the API
    return response
  },

  exchange_token: async function (public_token, clientID) {

    const response = await api.request({
      url: `plaid/exchangeToken`,
      method: "POST",
      data: {
        public_token: public_token,
        clientID: clientID,
      }
    })
    
    // returning the product returned by the API
    return response
  }
}