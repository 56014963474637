import { api } from "./config/axiosConfig"

export const BackEndAPI = {
  findUser: async function (phoneNumber, year, month, day) {

    console.log(phoneNumber, year, month, day);
    
    const response = await api.request({
        url: `azure/findUser`,
        method: "POST",
        data: {
            phoneNumber: phoneNumber,
            birthMonth: month,
            birthDay: day,
            birthYear: year
        }
    })
    
    // returning the product returned by the API
    return response
  },
}