import React, { useState } from 'react';

//Import plaidLink component
import PlaidLinkClass from './PlaidLinkClass.tsx';

const plaidPage = (props) => {

    return (
        <div className="App">
            <PlaidLinkClass clientID={props.clientID} name={props.name}/>
        </div>
    );
}

export default plaidPage;