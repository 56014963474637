import logo from './logo.svg';
import './App.css';

import SignIn from './components/signinComponent/signin';

import PlaidLinkClass from './components/plaidComponent/PlaidLinkClass.tsx';

import { Route, Routes } from "react-router-dom";


function App() {
  
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<SignIn />} />
      </Routes>
    </div>
  );
}

export default App;
